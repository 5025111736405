function cleanEmptyData() {
    const data = document.querySelectorAll('.remaining_lesson_wrapper');
    const filtered = [...data].filter(n => !n.innerHTML.includes('remaining_lessons'));
    console.log(filtered)

    for (var i = 0; i < filtered.length; i++) {
        filtered[i].classList.add('empty');
    }
}

function cleanMenuLabels() {
    const appLabel = document.querySelector('li.woocommerce-MyAccount-navigation-link--appointments-ext a');
    const accLabel = document.querySelector('li.woocommerce-MyAccount-navigation-link--edit-account a');

    appLabel.innerHTML = 'Termine';
    accLabel.innerHTML = 'Konto';
}

class ScrollController {
    constructor() {
        this.lastKnownScrollPosition = 0;
        this.ticking = false;
        this.scrollElement = null;
        this.updateScrollPoints(window.pageYOffset);
    }


    updateScrollDirection(delta) {
        if (delta >= 0) {
            document.body.classList.add('scroll-down');
            document.body.classList.remove('scroll-up');
        } else {
            document.body.classList.remove('scroll-down');
            document.body.classList.add('scroll-up');
        }
    }

    updateScrollPoints(scrollPosition) {
        let topDelta = 100;

        if(window.matchMedia('(min-width: 641px)').matches) {
            topDelta = 10;
        }

        if (scrollPosition <= topDelta) {
            document.body.classList.add('wav-top');
        } else {
            document.body.classList.remove('wav-top');
        }
    }

    onScroll() {
        const delta = window.pageYOffset - this.lastKnownScrollPosition;
        this.lastKnownScrollPosition = window.pageYOffset;

        if (!this.ticking) {
            window.requestAnimationFrame(() => {
                this.updateScrollDirection(delta);
                this.updateScrollPoints(this.lastKnownScrollPosition);
                this.ticking = false;
            });

            this.ticking = true;
        }
    }

    setEventListener() {
        document.addEventListener('scroll', () => this.onScroll());
    }
}

document.addEventListener("DOMContentLoaded", function() {

    if(document.querySelector('.swiper')){
      console.log("There are swiper fields!!")
      const swiper = new Swiper('.swiper', {
        loop: true,
      })
    }

    if (document.querySelector('.remaining_lesson_wrapper')) {
        const timeout = setTimeout(cleanEmptyData, 1500);
    }

    if (document.querySelector('.woocommerce-MyAccount-navigation')) {
        cleanMenuLabels();
    }

    if(document.querySelector('#customer_login')) {
      document.body.classList.add('login_register');
    }

    const scrollControll = new ScrollController();
    scrollControll.setEventListener();

    const pathURL = window.location.pathname;
    if(pathURL === '/mein-konto/') {
        document.body.classList.add('dashboard');
    }
});
